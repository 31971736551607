import Header from "../../components/header/Header";
import History from "../../components/history/History";

const HistoryPage = () => {
    return ( 
        <>
            <Header/>
            <History/>
        </>

     );
}

export default HistoryPage;