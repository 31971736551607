import axios from "./customize-axios";

const login = async (username, password) => {
  try {

    const response = await axios.post('auth/login', { username, password });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const logout = async (accessToken) => {
  try {
    const response = await axios.post('auth/logout', {
      token: accessToken

    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const changePassword = async (accessToken, formData) => {
  try {
    if (!accessToken) {
      throw new Error('Missing accessToken');
    }

    const response = await axios.post('auth/change-password', formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    // Xử lý lỗi
    console.error('Error in createCustomer:', error);
    throw error;
  }
};

export { login, logout, changePassword };