import './App.css';
import DashboardPage from './pages/dashboard/DashboardPage';

function App() {
  return (
    <DashboardPage/>
  );
}

export default App;
