import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { changePassword } from "../../services/AuthService";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const ChangePassword = ({ onCreateSuccess }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [imagePreview, setImagePreview] = useState(null);
  const user = localStorage.getItem("user");
  let userName = "";
  if (user) {
    let users = JSON.parse(user);
    userName = users.username;
  }

  const handleClose = () => {
    setShowModal(false);
    setImagePreview(null);
  };

  const handleShow = () => setShowModal(true);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};

    // Validate oldPassword
    if (!formData.oldPassword.trim()) {
      errors.name = "Password cũ không được để trống";
    }

    // Validate newPassword
    if (!formData.newPassword.trim()) {
      errors.numberPlate = "Password mới không được để trống";
    }

    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
      return;
    }
    if (Object.keys(errors).length === 0) {
      // Không có lỗi, tiếp tục gửi dữ liệu
      const formDataToSend = new FormData();

      formDataToSend.append("oldPassword", formData.oldPassword);
      formDataToSend.append("newPassword", formData.newPassword);
      formDataToSend.append("username", userName);
      try {
        const response = await changePassword(
          localStorage.getItem("jwtToken"),
          formDataToSend
        );
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("user");

        navigate("/login");
        onCreateSuccess();
        console.log("Response from createCustomer API:", response);
      } catch (error) {
        for (let i = 0; i < error.response.data.message.length; i++) {
          toast.error(
            error.response.data.message[i].defaultMessage +
              ". Vui lòng nhập lại."
          );
        }
      }
      handleClose();
    } else {
      // Có lỗi, hiển thị thông báo lỗi
      Object.values(errors).forEach((errorMsg) => {
        toast.error(errorMsg);
      });
    }
  };

  return (
    <>
      <i onClick={handleShow} class="uil uil-user">
        Change Password
      </i>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton style={{ backgroundColor: "gray" }}>
          <Modal.Title>Đổi Mật Khẩu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Mật Khẩu Cũ</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nhập Password cũ"
                name="oldPassword"
                value={formData.oldPassword}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formNumberPlate">
              <Form.Label>Mật Khẩu Mới</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nhập Password mới"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
              />
            </Form.Group>
            {imagePreview && (
              <div>
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="uil uil-lock" style={{ fontSize: "24pt" }}></i>
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            <i className="uil uil-bookmark" style={{ fontSize: "24pt" }}></i>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangePassword;
