import Header from "../../components/header/Header";
import Customer from "../../components/customer/Customer";

const CustomerPage= () => {
    return ( 
        <>
            <Header/>
            <Customer/>
        </>
     );
}

export default CustomerPage;