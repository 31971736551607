import { Button, Modal } from "react-bootstrap";
import styles from "./HistoryDetail.module.css";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { deleteHistory } from "../../services/UserService";
const HistoryDetail = ({ history, handleClose , changeFlag, setChangeFlag,}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes} ${day}-${month}-${year}`;
  }
  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteHistory(
        localStorage.getItem("jwtToken"),
        history.id
      );
      console.log("Response from createCustomer API:", response);
    } catch (error) {
      for (let i = 0; i < error.response.data.message.length; i++) {
        toast.error(
          error.response.data.message[i].defaultMessage + ". Vui lòng nhập lại."
        );
      }
    }
    handleCloseDeleteModal();
    handleClose();
    changeFlag(true);
  };

  return (
    <>
      <Modal.Header closeButton style={{ backgroundColor: "gray" }}>
        <Modal.Title style={{ fontSize: "20pt", color: "white" }}>
          Thông tin đơn hàng
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.history}>
          <div className={styles.content}>
            <label>Xe: </label>
            <span>{history.car_name}</span>
          </div>
          <div className={styles.content}>
            <label>Khách hàng: </label>
            <span>{history.customer_name}</span>
          </div>
          <div className={styles.content}>
            <label>Nhân viên: </label>
            <span>{history.user_name}</span>
          </div>
          <div className={styles.content}>
            <label>Ngày thanh toán: </label>
            <span>
              {" "}
              {moment(history.date_time).format("DD/MM/YYYY HH:MM:SS")}
            </span>
          </div>
          <div className={styles.content}>
            <label>Chi phí xe: </label>
            <span>
              {history.carCost
                ? history.carCost.toLocaleString("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  })
                : 0}
            </span>
          </div>
          <div className={styles.content}>
            <label>Chi phí phụ: </label>
            <span>
              {history.surcharge
                ? history.surcharge.toLocaleString("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  })
                : 0}
            </span>
          </div>
          <div className={styles.content}>
            <label>Tổng tiền: </label>
            <span>
              {history.total_revenue
                ? history.total_revenue.toLocaleString("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  })
                : 0}
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className={styles.btnDelete} onClick={handleShowDeleteModal}>
          <i className="uil uil-trash" style={{ fontSize: "24pt" }}></i>
        </Button>
        <Modal
          show={showDeleteModal}
          onHide={handleCloseDeleteModal}
          centered
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
          <Modal.Header closeButton style={{ backgroundColor: "gray" }}>
            <Modal.Title>Xác nhận xóa</Modal.Title>
          </Modal.Header>
          <Modal.Body>Bạn có chắc chắn muốn xóa lịch sử này không?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              <i className="uil uil-lock" style={{ fontSize: "24pt" }}></i>
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              <i className="uil uil-trash" style={{ fontSize: "24pt" }}></i>
            </Button>
          </Modal.Footer>
        </Modal>
        <Button variant="secondary" onClick={handleClose}>
          <i className="uil uil-lock" style={{ fontSize: "24pt" }}></i>
        </Button>
      </Modal.Footer>
    </>
  );
};

export default HistoryDetail;
